import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { MODAL_ANIMATION_TIME } from '../../../utils/helpers/constants';
import {
  StyledSearchContainer,
  StyledSearchInput,
  StyledSearchResetButton,
  StyledSearchLabel,
  IconContainer,
  SearchIconContainer
} from './styled';
import Icon from '../../Icon';
import EyeIcon from '../../../public/static/images/eye.svg';
import CloseEyeIcon from '../../../public/static/images/closed-eye.svg';
import { InputSize } from './types';
import { VISIBLE_VALUE_ICON_SIZE } from './constants';
import { tagStyles } from '../Typography/styles';

export const NewInput = ({
  id,
  stat,
  value,
  refEl,
  onKeyDown,
  placeholder,
  onBlurHandler,
  onResetHandler,
  onFocusHandler,
  onChangeHandler,
  isPin = false,
  inputMode = 'text',
  isAutoFocus = false,
  isShowReset = false,
  isInsideModalWithAnimation = false,
  isLabelAnimated = false,
  isShowSearch = true,
  isValueHidden = false,
  size = InputSize.Medium,
  cypress = 'search-input',
  inputTagStyle = tagStyles.body_1
}) => {
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [isValueVisible, setIsValueVisible] = useState(false);

  useEffect(() => {
    if (isAutoFocus && refEl.current) {
      if (isInsideModalWithAnimation) {
        setTimeout(() => {
          refEl.current.focus();
        }, MODAL_ANIMATION_TIME);
      } else {
        refEl.current.focus();
      }
    }
  }, [isAutoFocus]);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (!value) setIsFocused(false);
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement?.addEventListener('focus', handleFocus);
      inputElement?.addEventListener('blur', handleBlur);

      return () => {
        inputElement?.removeEventListener('focus', handleFocus);
        inputElement?.removeEventListener('blur', handleBlur);
      };
    }
  }, []);

  const toggleValueVisibility = () => {
    setIsValueVisible(!isValueVisible);

    if (inputRef?.current) {
      setTimeout(() => {
        const { length } = inputRef.current.value;
        inputRef.current.focus();
        inputRef.current.setSelectionRange(length, length);
      }, 0);
    }
  };

  return (
    <StyledSearchContainer
      data-stat={stat}
      isPin={isPin}
      isActive={isFocused || !!value}
      size={size}
    >
      {isShowSearch && (
        <SearchIconContainer {...{ size, isShowSearch, isPin }} />
      )}
      {isLabelAnimated && <StyledSearchLabel>{placeholder}</StyledSearchLabel>}
      <StyledSearchInput
        id={id}
        size={size}
        isShowSearch={isShowSearch}
        isShowReset={isShowReset}
        $inputTagStyle={inputTagStyle}
        isValueHidden={isValueHidden && value}
        ref={refEl || inputRef}
        type={isValueHidden && !isValueVisible ? 'password' : 'search'}
        name="search"
        value={value}
        tabIndex={-1}
        autoComplete="off"
        onKeyDown={onKeyDown}
        inputMode={inputMode}
        data-cy={cypress}
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        placeholder={placeholder}
        onChange={onChangeHandler}
        pattern={inputMode === 'numeric' ? '[0-9]*' : null}
        isLabelAnimated={isLabelAnimated}
      />
      <IconContainer size={size} position="right">
        {isValueHidden && value && (
          <Icon
            color="transparent"
            indent={false}
            width={VISIBLE_VALUE_ICON_SIZE}
            height={18}
            IconComponent={isValueVisible ? EyeIcon : CloseEyeIcon}
            onClick={toggleValueVisibility}
          />
        )}
        {isShowReset && (
          <StyledSearchResetButton
            role="button"
            tabIndex={-1}
            onClick={onResetHandler}
            onKeyPress={onResetHandler}
          />
        )}
      </IconContainer>
    </StyledSearchContainer>
  );
};

NewInput.propTypes = {
  value: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  isPin: PropTypes.bool,
  stat: PropTypes.string,
  refEl: PropTypes.object,
  isAutoFocus: PropTypes.bool,
  isShowReset: PropTypes.bool,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  onResetHandler: PropTypes.func,
  isInsideModalWithAnimation: PropTypes.bool,
  inputMode: PropTypes.oneOf(['text', 'numeric']),
  isLabelAnimated: PropTypes.bool,
  inputTagStyle: PropTypes.array,
  isShowSearch: PropTypes.bool,
  isValueHidden: PropTypes.bool,
  size: PropTypes.oneOf([InputSize.Medium, InputSize.Large]),
  cypress: PropTypes.string
};
